@import url(//hello.myfonts.net/count/337839);
/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: 'Avenir Book';
  src: url(/static/media/337839_2_0.001ea96b.eot);src: url(/static/media/337839_2_0.001ea96b.eot?#iefix) format('embedded-opentype'),url(/static/media/337839_2_0.db4d0ef7.woff2) format('woff2'),url(/static/media/337839_2_0.ca303837.woff) format('woff'),url(/static/media/337839_2_0.9052468c.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/337839_0_0.895b2de4.eot);src: url(/static/media/337839_0_0.895b2de4.eot?#iefix) format('embedded-opentype'),url(/static/media/337839_0_0.07ba6a74.woff2) format('woff2'),url(/static/media/337839_0_0.1496fb65.woff) format('woff'),url(/static/media/337839_0_0.799da331.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/337839_3_0.b1ed13b6.eot);src: url(/static/media/337839_3_0.b1ed13b6.eot?#iefix) format('embedded-opentype'),url(/static/media/337839_3_0.b14788d2.woff2) format('woff2'),url(/static/media/337839_3_0.1083c509.woff) format('woff'),url(/static/media/337839_3_0.4197d42e.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/337839_4_0.7903bd0b.eot);src: url(/static/media/337839_4_0.7903bd0b.eot?#iefix) format('embedded-opentype'),url(/static/media/337839_4_0.3dcef336.woff2) format('woff2'),url(/static/media/337839_4_0.cc720256.woff) format('woff'),url(/static/media/337839_4_0.2e3b7097.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url(/static/media/337839_5_0.ff5c8e62.eot);src: url(/static/media/337839_5_0.ff5c8e62.eot?#iefix) format('embedded-opentype'),url(/static/media/337839_5_0.a4e6a14f.woff2) format('woff2'),url(/static/media/337839_5_0.6d54e9cf.woff) format('woff'),url(/static/media/337839_5_0.a25f81ae.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/337839_1_0.6403cfac.eot);src: url(/static/media/337839_1_0.6403cfac.eot?#iefix) format('embedded-opentype'),url(/static/media/337839_1_0.8e92a51c.woff2) format('woff2'),url(/static/media/337839_1_0.a0a2a097.woff) format('woff'),url(/static/media/337839_1_0.057ebf96.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url(/static/media/TiemposText-Regular.d3ce97c2.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.11eabca2.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url(/static/media/Roboto-Bold.e07df86c.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
