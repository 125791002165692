/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/337839");

@font-face {
  font-family: 'Avenir Book';
  src: url('./337839_2_0.eot');src: url('./337839_2_0.eot?#iefix') format('embedded-opentype'),url('./337839_2_0.woff2') format('woff2'),url('./337839_2_0.woff') format('woff'),url('./337839_2_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./337839_0_0.eot');src: url('./337839_0_0.eot?#iefix') format('embedded-opentype'),url('./337839_0_0.woff2') format('woff2'),url('./337839_0_0.woff') format('woff'),url('./337839_0_0.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('./337839_3_0.eot');src: url('./337839_3_0.eot?#iefix') format('embedded-opentype'),url('./337839_3_0.woff2') format('woff2'),url('./337839_3_0.woff') format('woff'),url('./337839_3_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./337839_4_0.eot');src: url('./337839_4_0.eot?#iefix') format('embedded-opentype'),url('./337839_4_0.woff2') format('woff2'),url('./337839_4_0.woff') format('woff'),url('./337839_4_0.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('./337839_5_0.eot');src: url('./337839_5_0.eot?#iefix') format('embedded-opentype'),url('./337839_5_0.woff2') format('woff2'),url('./337839_5_0.woff') format('woff'),url('./337839_5_0.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./337839_1_0.eot');src: url('./337839_1_0.eot?#iefix') format('embedded-opentype'),url('./337839_1_0.woff2') format('woff2'),url('./337839_1_0.woff') format('woff'),url('./337839_1_0.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url('./TiemposText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('./Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}